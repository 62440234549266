<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <el-col :md="8" :xs="24">
          <el-row type="flex" justify="start">
            <h4>{{ $route.name }}</h4>
          </el-row>
        </el-col>
        <el-col :md="16" :xs="24">
          <el-row type="flex" justify="end">
            <el-dropdown
              @command="(c) => filterBills(c)"
              class="mr-8"
              size="medium"
              trigger="click"
            >
              <el-button type="primary" size="medium">
                Exibir contas por: {{ displayPage || ""
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="Em aberto">
                    <li
                      :class="{
                        'el-dropdown-menu__item': true,
                        'no-padding': true,
                        active: displayPage === 'Em aberto',
                      }"
                    >
                      Em aberto
                    </li>
                  </el-dropdown-item>
                  <el-dropdown-item command="Indiferente">
                    <li
                      :class="{
                        'el-dropdown-menu__item': true,
                        'no-padding': true,
                        active: displayPage === 'Indiferente',
                      }"
                    >
                      Indiferente
                    </li>
                  </el-dropdown-item>
                  <el-dropdown-item command="Vencidas"
                    ><li
                      :class="{
                        'el-dropdown-menu__item': true,
                        'no-padding': true,
                        active: displayPage === 'Vencidas',
                      }"
                    >
                      Vencidas
                    </li></el-dropdown-item
                  >
                  <el-dropdown-item command="Baixadas"
                    ><li
                      :class="{
                        'el-dropdown-menu__item': true,
                        'no-padding': true,
                        active: displayPage === 'Baixadas',
                      }"
                    >
                      Baixadas
                    </li></el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <el-button
              type="primary"
              class="mb-0"
              icon="el-icon-download"
              :loading="isLoadingDownloadButton"
              size="medium"
              @click="downloadReport()"
            ></el-button>
            <el-button
              type="primary"
              icon="el-icon-s-operation"
              size="medium"
              @click="openFilterBillsModal()"
            ></el-button>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :data="bills"
      style="width: 100%; z-index: 0"
      :cell-style="() => 'text-align:center;'"
    >
      <el-table-column prop="bill.firm.name" label="empresa"> </el-table-column>
      <el-table-column prop="bill.partner.name" label="fornecedor">
      </el-table-column>
      <el-table-column prop="bill.cost.reference" label="centro de custo">
      </el-table-column>
      <el-table-column
        label="adquirido em"
        :formatter="(r) => formatDate(new Date(r?.bill?.bought_at))"
      ></el-table-column>
      <el-table-column prop="bill.invoice_id" label="nota fiscal">
      </el-table-column>
      <el-table-column
        label="vencimento"
        :formatter="(r) => formatDate(new Date(r?.expires_at))"
      ></el-table-column>
      <el-table-column label="parcelas" :formatter="(r) => formatOrders(r)">
      </el-table-column>
      <el-table-column
        label="valor"
        :formatter="(r) => formatCurrency(r.amount)"
      >
      </el-table-column>
    </el-table>
    <el-row v-show="bills?.data?.length">
      <el-col :md="3">
        <p class="summary summary-black">Total:</p>
      </el-col>
      <el-col :md="19"></el-col>
      <el-col :md="2">
        <p class="summary">
          {{
            currencyFormatter.format(
              bills?.data?.reduce((t, b) => (t += Number(b?.amount)), 0) || 0
            )
          }}
        </p>
      </el-col>
    </el-row>
    <filter-bills-modal
      :showModal="showFilterBillsModal"
      @close-modal="showFilterBillsModal = false"
      @update-filters="updateFilters"
    ></filter-bills-modal>
  </el-card>
</template>

<script>
import { ElNotification /*ElMessageBox*/ } from "element-plus";
import FilterBillsModal from "./modals/FilterBillsModal.vue";
export default {
  name: "BillsReportPage",
  components: { FilterBillsModal },
  data: () => ({
    hasError: false,
    isLoading: true,
    bills: null,
    bill: null,
    dateFormatter: new Intl.DateTimeFormat("pt-BR", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }),
    referenceDate: new Date(),
    ref: null,
    displayPage: "",
    displayOptions: {},
    filterOptions: {},
    currentPage: 1,
    showFilterBillsModal: false,
    currencyFormatter: new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }),
    isLoadingDownloadButton: false,
  }),
  mounted() {
    this.filterBills("Em aberto");
  },
  watch: {
    currentPage() {
      this.fetchBills();
    },
    SearchQuery() {
      this.fetchBills();
    },
  },
  computed: {
    SearchQuery() {
      return this.$store.state.searchNameQuery;
    },
  },
  methods: {
    changeDownloadButtonStatus() {
      this.isLoadingDownloadButton = !this.isLoadingDownloadButton;
    },
    downloadReport() {
      this.changeDownloadButtonStatus();

      ElNotification.info({
        title: "Gerando o relatório",
        message: "Isso pode levar alguns segundos",
      });

      const url = new URL(`${this.$store.state.apiUrl}bills/report`);
      url.search = new URLSearchParams({
        ...(this.filterOptions || {}),
      });
      fetch(url, {
        credentials: "include",
        headers: {
          Accept: "blob",
        },
      })
        .then((response) => {
          if (response.ok) return response.blob();
          else throw response.json();
        })
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `Relatório de Contas.pdf`;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(async (e) => {
          ElNotification.error({
            title: "Ocorreu um erro",
            message: (await e).message,
          });
        })
        .finally(() => this.changeDownloadButtonStatus());
    },
    openFilterBillsModal() {
      this.showFilterBillsModal = true;
    },
    formatCurrency(c) {
      return c ? this.currencyFormatter.format(c) : "R$ 0,00";
    },
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return new Date(c).toLocaleDateString();
      else return "desconhecido";
    },
    allPaid(payments) {
      return payments?.every((p) => p.status === "paid");
    },
    getFirstUnpaidPayment(bill) {
      if (!bill) return null;
      let unpaid;
      if (this.allPaid(bill.payments))
        unpaid = bill.payments[bill.payments.length - 1];
      else unpaid = bill?.payments.find((p) => p.status != "paid");
      if (!unpaid)
        unpaid = {
          order: 1,
          total: 1,
          expires_at: bill.expires_at,
          amount: bill.amount,
        };

      return unpaid;
    },
    updateFilters(filters) {
      if (filters) this.filterOptions = filters;
      this.fetchBills();
    },
    formatOrders(b) {
      if (b?.payments) {
        const order = b.payments.reduce(
          (t, p) => (t += p.status === "paid" ? 1 : 0),
          0
        );
        return `${order || 0}/${b.quota || 1}`;
      }
      return `${b.order || "1"}/${b.total || "1"}`;
    },
    getAmountPaid(bill) {
      if (bill?.payments) {
        return this.formatCurrency(
          bill.payments.reduce(
            (t, p) => (t += p.status === "paid" ? p.amount_paid : 0),
            0
          )
        );
      }
      return this.formatCurrency(0);
    },
    filterBills(filter) {
      let shouldCallFetch = this.displayPage != filter;

      this.displayPage = filter;
      if (!this.filterOptions) this.filterOptions = {};
      let _;
      this.displayOptions = {};
      switch (filter) {
        case "Em aberto":
          this.displayOptions.status = "unpaid";
          break;
        case "Vencimento no dia":
          _ = new Date();
          this.displayOptions.status = "unpaid";
          this.displayOptions.expires_at_start = new Date(
            _.setUTCHours(0, 0, 0, 0)
          );
          this.displayOptions.expires_at_end = new Date(
            _.setUTCHours(23, 59, 59, 999)
          );
          break;
        case "Vencidas":
          _ = new Date();
          _.setUTCHours(0, 0, 0, 0);
          this.displayOptions.status = "unpaid";
          this.displayOptions.expires_at_end = _;
          break;
        case "Baixadas":
          this.displayOptions.status = "paid";
          break;
      }
      if (shouldCallFetch) this.fetchBills();
    },
    fetchBills() {
      this.isLoading = true;
      const url = new URL(`${this.$store.state.apiUrl}payments`);

      this.filterBills(this.displayPage);

      url.search = new URLSearchParams({
        ...(this.filterOptions || {}),
        ...(this.displayOptions || {}),
        searchName: this.SearchQuery,
      });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.bills = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
<style>
.el-card {
  width: 100%;
}
li.active {
  background-color: #409eff;
  color: white;
  padding-left: 17px;
}
li.no-padding {
  margin-left: -17px !important;
  margin-right: -17px !important;
}
.mr-8 {
  margin-right: 8px !important;
}
</style>